<template>
    <v-dialog v-model="isOpen" max-width="800px" persistent>
        <v-card style="position: relative;">
            <v-card-title class="px-0 pt-4 pb-0 d-block" style="position: sticky; top: 0px; background: white; z-index: 100;">
                <div class="d-flex align-center px-4 pb-4" style="gap: 8px;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                    <h5 class="text-h6 secondary--text text-uppercase font-weight-bold">
                        Detalles de subasta
                    </h5>
                </div>
                <v-divider />
            </v-card-title>
            <v-card-text class="px-4 pt-4 pb-0">
                <template v-if="subasta">
                    <!-- Detalles de configuracion-->
                    <h5 class="text-subtitle-2 text-uppercase font-weight-bold mb-2">
                        <v-icon style="font-size: 22px;" class="mr-1">mdi-wrench</v-icon>
                        Configuración de subasta
                    </h5>
                    <v-divider />
                    <div class="d-flex flex-column flex-md-row grupo-detalles" style="gap: 4px;">
                        <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%;">
                            <dt class="font-weight-bold text-body-2 mt-3">Fecha de creación:</dt>
                            <dd class="text-body-2">{{ formatDate(subasta.created_at) }}</dd>
    
                            <dt class="font-weight-bold text-body-2 mt-3">Fecha de última modificación:</dt>
                            <dd class="text-body-2">{{ formatDate(subasta.updated_at) }}</dd>
    
                            <dt class="font-weight-bold text-body-2 mt-3">Cuenta regresiva de puja:</dt>
                            <dd class="text-body-2">{{ subasta.cuenta_atras_puja ? 'Si' : 'No' }}</dd>
    
                            <dt class="font-weight-bold text-body-2 mt-3">Cuenta regresiva de cierre:</dt>
                            <dd class="text-body-2">{{ subasta.cuenta_atrás_cierre ? 'Si' : 'No' }}</dd>
                        
                            <dt class="font-weight-bold text-body-2 mt-3">Aviso de cuenta regresiva:</dt>
                            <dd class="text-body-2">{{ subasta.tiempo_atras_aviso ? 'Si' : 'No' }}</dd>

                            <dt class="font-weight-bold text-body-2 mt-3">Porcentaje inicial:</dt>
                            <dd class="text-body-2">{{ subasta.porcentaje_inicial ?? '-' }}</dd>

                            <dt class="font-weight-bold text-body-2 mt-3">Salto de oferta:</dt>
                            <dd class="text-body-2">
                                {{ 
                                    Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(subasta.salto_oferta ?? 0)
                                }}
                            </dd>

                        </dl>

                        <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%;">
                            <dt class="font-weight-bold text-body-2 mt-3">Fecha de inicio:</dt>
                            <dd class="text-body-2">{{ formatDate(subasta.fecha_hora_inicio) }}</dd>
    
                            <dt class="font-weight-bold text-body-2 mt-3">Fecha de fin:</dt>
                            <dd class="text-body-2">{{ formatDate(subasta.fecha_hora_final) }}</dd>
    
                            <dt class="font-weight-bold text-body-2 mt-3">Fecha programada de finalización:</dt>
                            <dd class="text-body-2">{{ formatDate(subasta.fecha_programada_finalizacion) }}</dd>
    
                            <dt class="font-weight-bold text-body-2 mt-3">Tiempo de cierre:</dt>
                            <dd class="text-body-2">{{ subasta.tiempo_cierre ?? '-' }}</dd>
                        
                            <dt class="font-weight-bold text-body-2 mt-3">Tiempo máximo de oferta:</dt>
                            <dd class="text-body-2">{{ subasta.tiempo_maximo_oferta ?? '-' }}</dd>

                            <dt class="font-weight-bold text-body-2 mt-3">Tiempo máximo de desierto:</dt>
                            <dd class="text-body-2">{{ subasta.tiempo_maximo_desierto ?? '-' }}</dd>
                        </dl>
                    </div>

                    <!-- Detalles internos-->
                    <h5 class="text-subtitle-2 text-uppercase font-weight-bold mt-8 mb-2">
                        <v-icon style="font-size: 22px;" class="mr-1">mdi-home-search</v-icon>
                        Detalles internos
                    </h5>
                    <v-divider />
                    <div class="d-flex flex-column flex-md-row grupo-detalles" style="gap: 4px;">
                        <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%;">
                            <dt class="font-weight-bold text-body-2 mt-3">ID de subasta:</dt>
                            <dd class="text-body-2">{{ subasta.id }}</dd>
    
                            <dt class="font-weight-bold text-body-2 mt-3">ID de proceso compra:</dt>
                            <dd class="text-body-2">{{ subasta.id_proceso ?? '-' }}</dd>
                        </dl>
                        <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%;">
                            <dt class="font-weight-bold text-body-2 mt-3">ID de lote:</dt>
                            <dd class="text-body-2">{{ subasta.id_lote_proceso ?? '-' }}</dd>

                            <dt class="font-weight-bold text-body-2 mt-3">ID de sala:</dt>
                            <dd class="text-body-2">{{ subasta.id_sala ?? '-' }}</dd>
                        </dl>
                    </div>
                </template>

                <div class="d-flex justify-center align-center mt-8 py-4" style="position: sticky; bottom: 0; background: white;">
                    <v-btn color="primary" @click.stop="cerrarModal">Cerrar</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { formatDate } from '@/utils/datetime';

export default {
    name: 'ModalDetalleSubasta',
    props: {
        isOpen: { type: Boolean },
        subasta: { type: Object }
    },
    data: () => ({
    }),
    computed: {
    },
    methods: {
        formatDate,
        cerrarModal() {
            this.$emit('update:is-open', false);
        },
    },
}
</script>
<style scoped>
@media (min-width: 960px) {
    .grupo-detalles {
        gap: 24px;
    }
}
</style>